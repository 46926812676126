<template>
    <div class="check-code">
        <a-spin v-if="checking"></a-spin>
        <template v-else>
            <span class="icon-result" :class="checkPass ? 'success' : 'fail'">
                <i :class="checkPass ? 'icon-ok' : 'icon-cancel-1'"></i>
            </span>
            <p class="result-text">{{checkPass ? $t('user.activatedSuccessfully') : $t('user.activationFails')}}</p>
            <p style="color: #999;">{{$t('user.jumpingForYou')}}</p>
        </template>
    </div>
</template>
<script>
import { checkCode } from 'services/index';
export default {
    name: 'checkCode',
    data() {
        return {
            checking: true,
            checkParams: {
                code: this.$route.query.code,
                email: this.$route.query.email,
            },
            checkPass: false,
        }
    },
    mounted() {
        checkCode(this.checkParams)
        .then(res => {
            this.checking = false;
            if(res.code === 0) {
                this.checkPass = true;
                setTimeout(() => {
                    this.$router.push({name: 'login'});
                }, 3000)
            }else {
                this.checkPass = false;
            }
        })
    }
}
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.check-code {
    width: @container;
    margin: 50px auto;
    text-align: center;
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @size: 72px;
    .icon-result {
        display: block;
        width: @size;
        height: @size;
        margin: 0 auto 24px;
        border-radius: 50%;
        line-height: @size;
        color: #fff;
        font-size: 36px;
        &.success {
            background-color: @green-color;
        }
        &.fail {
            background-color: @red-color;
        }
    }
    .result-text {
        font-size: 24px;
        line-height: 1.8;
        text-align: center;
    }
}
</style>